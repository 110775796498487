import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';

import './call-modal.scss';
import CallIcon from '../../../assets/images/callIcon';
import EditIcon from '../../../assets/images/editIcon';
import DeleteIcon from '../../../assets/images/deleteIcon';
import CrossIcon from '../../../assets/images/crossIcon';
import CheckIcon from '../../../assets/images/checkIcon';
import ConfirmationMessage from './confirmation-message';
import DefaultIcon from '../../../assets/images/default-icon';
import { useDispatch, useSelector } from 'react-redux';
import { callDealer, refreshDealers, setCorrelationId, setDefaultDealerNumber, setOngoingCallStatus } from '../../../../store/actions/myDealers';
import { getCallEvent, showToastMessage } from '../../../utils/utils';
import { ALL_CARS_TABLE_VIEW, EVENT_CAR_SOURCE } from '../../../constants/table.constants';
import { handleAddContact } from './actions/add';
import { handleDelete } from './actions/delete';
import { handleEditClick, handleSaveClick } from './actions/edit';
import { getLoginUserEmail } from '../../../../../../utils/utils';
import { trackAnalytics } from '../../../services/analytics';
import { SegmentEvents } from '../../../constants/events.constants';

const CallModal = forwardRef(({ dealerId, dealerContactNumbers, closeModal, dealerName }, ref) => {
  const { isCallOngoing } = useSelector((state) => state.myDealers);
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNumberAdded, setIsNumberAdded] = useState(false);
  const [newNumber, setNewNumber] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { userInfo } = useSelector((state) => state.allCars);
  const [isDefaultConfirmation, setIsDefaultConfirmation] = useState(false);

  const dispatch = useDispatch();
  const email = getLoginUserEmail();

  const pathName = window.location.pathname;

  const modalRef = useRef(null);


  useEffect(() => {
    const adjustModalPosition = () => {
      const modalElement = modalRef.current;
      const tableElement = ref.current;

      if (modalElement && tableElement) {
        const modalRect = modalElement.getBoundingClientRect();
        const tableRect = tableElement.getBoundingClientRect();
        const availableHeightBelowTable = window.innerHeight - tableRect.bottom;

        if (modalRect.height > availableHeightBelowTable) {
          modalElement.style.top = `-${availableHeightBelowTable}px`;
        }


      }
    };

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    adjustModalPosition();
    window.addEventListener('resize', adjustModalPosition);

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', adjustModalPosition);
    };
  }, [modalRef]);

  useEffect(() => {
    if (!isEditModalOpen) {
      resetEditState();
    }
  }, [isEditModalOpen]);

  const resetEditState = () => {
    setIsEdit(false);
    setEditIndex(null);
    setEditValue('');
  };




  const handleCancelClick = resetEditState;

  const handleDeleteClick = (index) => {
    setEditIndex(index);
    setIsDelete(true);
    setEditValue(dealerContactNumbers[index].number);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setEditValue(value);
    }
  };


  const handleSetDefault = (index) => {
    setEditIndex(index);
    setIsDefaultConfirmation(true);
    setEditValue(dealerContactNumbers[index].number);
  };

  const confirmSetDefault = () => {

    let trackData = {
      dealer_name: dealerName,
      dealer_id: dealerId,
      email_id: email,
      old_default_number: dealerContactNumbers.find((contact) => contact.isDefault).number,
      new_default_number: dealerContactNumbers[editIndex].number
    }

    dispatch(setDefaultDealerNumber({ dealerId, contactNumber: dealerContactNumbers[editIndex].number })).then(() => {
      setIsDefaultConfirmation(false);
      showToastMessage('Default contact set successfully ', true, 1000);
      dispatch(refreshDealers(false));
      trackAnalytics(SegmentEvents.Viz_contact_modification, trackData);
    }).catch(() => {
      showToastMessage('Failed to set default contact', false, 1000);
    });
  };

  const canEditOrDelete = (type) => type === 'ADDITIONAL';
  const isTwoAdditionalNumbers = dealerContactNumbers?.filter((contact) => contact.type === 'ADDITIONAL').length === 2;
  const canAddNewNumber = !isTwoAdditionalNumbers && !pathName.includes((ALL_CARS_TABLE_VIEW.all).toLowerCase());
  const isOnAllCarsPage = pathName.includes((ALL_CARS_TABLE_VIEW.all).toLowerCase());


  const handleCallDealer = (contact) => {
    const userId = userInfo?.id;
    const callingNumber = contact.number;
    const source = isOnAllCarsPage ? EVENT_CAR_SOURCE.cars : EVENT_CAR_SOURCE.dealers;
    dispatch(callDealer({ dealerId, userId, callingNumber })).then((resp) => {
      getCallEvent(resp, source);
      dispatch(setCorrelationId(resp?.clientCorrelationId));
      dispatch(setOngoingCallStatus(true));
    }).catch(() => {
      showToastMessage('Failed to call dealer', false, 1000);
    });
  };

  return (
    <div className='call-modal-container' ref={modalRef}>
      <div className='call-modal-header'>
        <div className='call-modal-heading'>
          <span className='title-color'>
            {!isEditModalOpen ? "Other contact Number" : "Set Default, Edit or Delete Number"}
          </span>
          <Button fillMode='link' disabled={isCallOngoing} className='modify-button' onClick={() => setIsEditModalOpen(!isEditModalOpen)}>
            {isEditModalOpen ? <CrossIcon /> : !isOnAllCarsPage ? "Modify" : null}
          </Button>
        </div>

        <div className='call-modal-body'>
          {dealerContactNumbers?.map((contact, index) => {
            return (
              <div key={index}>
                <div className='contact-header'>
                  <div className='contact-container'>
                    {isEdit && editIndex === index ? (
                      <Input
                        type='number'
                        className='contact-input'
                        value={editValue}
                        onChange={handleInputChange}
                      />
                    ) : (
                      (!isDelete || editIndex !== index) && (!isDefaultConfirmation || editIndex !== index) && (
                        <div className='contact-info'>
                          <DefaultIcon fill={contact.isDefault ? "#F19812" : null} />
                          <div className='contact-details'>
                            <span className='contact-number'>{contact.number}</span>
                            {contact?.isDefault && <span className='default-text'>Default</span>}
                            {isEditModalOpen && !contact.isDefault ? (
                              <Button fillMode="link" className='default-text' onClick={() => handleSetDefault(index)}>Set as default</Button>
                            ) : null}
                          </div>
                        </div>
                      )
                    )}

                    {isDelete && editIndex === index ? (
                      <ConfirmationMessage editValue={editValue} setIsDelete={setIsDelete} handleDelete={() => {
                        const contactNumber = dealerContactNumbers[editIndex].number;
                        handleDelete(dispatch, dealerId, contactNumber, setIsDelete, email, dealerName)
                      }
                      } />
                    ) : null}

                    {isDefaultConfirmation && editIndex === index ? (
                      <ConfirmationMessage editValue={editValue} defaultIndex={true} setIsDelete={setIsDefaultConfirmation} handleDelete={confirmSetDefault} />
                    ) : null}
                  </div>

                  {isEditModalOpen && !isDelete && !isDefaultConfirmation && canEditOrDelete(contact.type) && (
                    <div className='edit-container'>
                      {isEdit && editIndex === index ? (
                        <>
                          <CrossIcon onClick={handleCancelClick} />
                          <CheckIcon onClick={() => handleSaveClick(editValue, editIndex, dealerContactNumbers, dealerId, dispatch, resetEditState, dealerName, email)} />
                        </>
                      ) : (
                        <>
                          <EditIcon onClick={() => handleEditClick(index, dealerContactNumbers, setEditIndex, setEditValue, setIsEdit)} />
                          <DeleteIcon onClick={() => handleDeleteClick(index)} />
                        </>
                      )}
                    </div>
                  )}
                  {!isEditModalOpen && (
                    <Button disabled={isCallOngoing} className="modal-call-icon-button" onClick={() => handleCallDealer(contact)}>
                      <CallIcon fill="#EF6E0B" width={16} height={16} />
                    </Button>
                  )}
                </div>
                <hr />
              </div>
            );
          })}
        </div>

        {canAddNewNumber && (
          <span
            className={isCallOngoing ? "disabled-title-color" : "title-color"}
            onClick={() => !isCallOngoing && setIsNumberAdded(true)}
          >
            + Add new number
          </span>
        )}

        {isOnAllCarsPage && (
          <span className='modify-info-message'>
            To modify numbers please modify from the My Dealer’s page.
          </span>
        )}

        {isNumberAdded && (
          <div className='contact-header'>
            <div className='contact-container'>
              <Input
                type='number'
                className='contact-input'
                value={newNumber}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setNewNumber(e.target.value);
                  }
                }}
              />
            </div>
            <div className='edit-container'>
              <CrossIcon onClick={() => setIsNumberAdded(false)} />
              <CheckIcon onClick={() => handleAddContact(newNumber, dealerId, dispatch, setIsNumberAdded, setNewNumber, email, dealerName)} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default CallModal;
