import { useEffect, useRef, useState, } from 'react';

import "../common/assets/styles/common.scss";
import AppTable from "../common/components/app-table";
import AppTableCell from "../common/components/app-table-cell";
import { INITIAL_TABLE_SETTINGS, FIELDS_KEY, TABLE_LABELS, CARD_TYPE, EVENT_CAR_SOURCE } from "../common/constants/table.constants"
import "./my-dealers.scss";
import MyDealersFilters from "./my-dealers-filters"
import { useDispatch, useSelector } from "react-redux";
import { callDealer, fetchMyDealers, setDealerFilterQueryParam, setCorrelationId, setOngoingCallStatus, setSidebarDealerFilterPayloads, setSidebarDealerFilterApplied, setDealerFilterStatus, getReassignPA, updateDealersFilters } from "../store/actions/myDealers";
import { shareCar } from "../store/actions/allCars";
import ShareCarModal from "../common/components/share-car-modal";
import ConfirmContactedModal from "../common/components/confirm-contacted-modal";
import OnGoingPais from "../all-pais/on-going-pais";
import { DEFAULT_VALUE } from "../common/constants/fields.constants";
import { AppLoader } from '../common/components/app-loader';
import TablePageInfo from '../common/components/table-page-info';
import { formatNumbers, queryStringToObject, getCallEvent, showToastMessage } from '../common/utils/utils';
import ContactCell from '../common/components/contact-cell';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AssignModal from '../tl-view/assign-dealer-modal';
import PaConfirmationModal from '../tl-view/pa-confirmation-modal';
import { MY_DEALERS_TL_VIEW_DEFAULT_FILTER, TL_VIEW_CALENDAR } from '../common/constants/filters.constants';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { userTypes } from '../../../utils/constants/user-types.constants';

const MyDealers = ({ view }) => {
	const CustomCell = cellProps => <AppTableCell {...cellProps} onRowClick={() => { }}
		type={CARD_TYPE.dealers} />;
	const [page, setPage] = useState(INITIAL_TABLE_SETTINGS);
	const [searchValue, setSearchValue] = useState('');
	const dispatch = useDispatch();
	const showShareCarModal = useSelector((state) => state.allCars?.shareCar);
	const dealers = useSelector((state) => state.myDealers);
	const { userInfo } = useSelector((state) => state.allCars);
	const { filters, isCallOngoing, isLoading, dealerFilterQueryParam, markAsContactedConfirm, callingView, refreshRequired } = useSelector((state) => state.myDealers);
	const isInitialPageLoad = useRef(true);
	const tableRef = useRef(null);
	const [selectedContact, setSelectedContact] = useState(null);
	const location = useLocation();
	const history = useHistory();
	const params = new URLSearchParams(window.location.search);
	const [selectedDealers, setSelectedDealers] = useState([]);
	const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
	const [selectedPA, setSelectedPA] = useState(null)
	const [filter, setFilter] = useState(TL_VIEW_CALENDAR[0])
	const [resetSelection, updateResetSelection] = useState(false);
	const [selected, setSelected] = useState(0);
	const [count, setCount] = useState(0)

	const [date, setDate] = useState({
		dateRange: {
			start: new Date(),
			end: new Date()
		}
	})

	const isTLView = view === userTypes.TEAM_LEAD;
	const searchQuery = searchValue || (isTLView && filters?.filterBy !== MY_DEALERS_TL_VIEW_DEFAULT_FILTER[0] ? filters.filterBy?.id : '');

	useEffect(() => {
		if (isInitialPageLoad.current && (userInfo.type === userTypes.PROCUREMENT_ASSOCIATE)) {

			dispatch(fetchMyDealers({
				sortBy: filters.sortBy?.id,
				filterBy: filters.dealerType?.id,
				page: page.skip,
				size: page.take,
				userId: userInfo?.id,
				searchQuery,
				userType: userInfo?.type,
			}, true, true)).then((response) => {
				setCount(response?.pagination?.totalElements);
			});
			return;

		};


		const params = new URLSearchParams({ page: 1 });
		const { pathname } = location;

		history.push({ pathname, search: params.toString() });

		dispatch(setDealerFilterQueryParam(params.toString()));
		dispatch(setSidebarDealerFilterPayloads(null));
		dispatch(setSidebarDealerFilterApplied(null));
		dispatch(setDealerFilterStatus(false));

		if (page?.skip === INITIAL_TABLE_SETTINGS.skip) {
			refreshPageData()
		} else {
			setPage({
				skip: INITIAL_TABLE_SETTINGS.skip,
				take: INITIAL_TABLE_SETTINGS.take,
			});
		}

	}, [filters, searchValue, refreshRequired, selected]);


	useEffect(() => {
		if (!isInitialPageLoad.current) {
			refreshPageData()
		}
	}, [page])

	useEffect(() => {

		if (dealerFilterQueryParam) {

			const queryParams = queryStringToObject(dealerFilterQueryParam);


			setPage({
				skip: (queryParams['page'] ? queryParams['page'] : 1) - 1,
				take: INITIAL_TABLE_SETTINGS.take
			});

			history.push({
				pathname: location.pathname,
				search: `?${dealerFilterQueryParam}`
			})

		}
		else {

			const page = params.get('page') || 1;
			const paramLength = Array.from(params.entries()).length;

			setPage({
				skip: page - 1,
				take: INITIAL_TABLE_SETTINGS.take
			});

			history.push({
				pathname: location.pathname,
				search: paramLength > 0 ? params.toString() : `?page=${page}`
			})

			dispatch(setDealerFilterQueryParam(params.toString()));

		}

		dispatch(getReassignPA(userInfo?.id));

		isInitialPageLoad.current = false;

	}, []);


	const SafeCells = safeCellProps => {
		return (
			<td className='contact-cell' onClick={() => { }}>
				{safeCellProps.children}
			</td>
		);
	};

	const tableColumns = [
		{
			field: FIELDS_KEY.CompanyName,
			title: TABLE_LABELS.CompanyName,
			cells: CustomCell,
			minWidth: 180,

		},
		{
			field: FIELDS_KEY.DealerName,
			title: TABLE_LABELS.DealerName,
			cells: CustomCell,
			maxWidth: 120
		},
		{
			field: FIELDS_KEY.DealersCode,
			title: TABLE_LABELS.DealersCode,
			cells: CustomCell,
			maxWidth: 80
		},
		{
			field: FIELDS_KEY.PaName,
			title: TABLE_LABELS.PaName,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<span className='pa-name'>{dataItem?.paInfo?.name}</span>
				</SafeCells>
			),
			maxWidth: 120,
			show: view === userTypes.TEAM_LEAD
		},
		{
			field: FIELDS_KEY.PaEmail,
			title: TABLE_LABELS.PaEmail,
			cells: ({ dataItem }) => {
				return <SafeCells data={dataItem}>
					<span className='pa-email'>{dataItem?.paInfo?.email}</span>
				</SafeCells>
			},
			maxWidth: 220,
			show: view === userTypes.TEAM_LEAD
		},
		{
			field: FIELDS_KEY.CarAge,
			title: TABLE_LABELS.CarAge,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.carAge}</div>
				</SafeCells>
			),
			show: view !== userTypes.TEAM_LEAD,
			maxWidth: 100
		},
		{
			field: FIELDS_KEY.KMDriven,
			title: TABLE_LABELS.KMDriven,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{formatNumbers(dataItem?.dealerPreference?.kmDriven)}</div>
				</SafeCells>
			),
			show: view !== userTypes.TEAM_LEAD,
			maxWidth: 100
		},
		{
			title: TABLE_LABELS.BodyType,
			field: FIELDS_KEY.BodyType,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.bodyType}</div>
				</SafeCells>
			),
			show: view !== userTypes.TEAM_LEAD,
			maxWidth: 120
		},
		{
			field: FIELDS_KEY.ASP,
			title: TABLE_LABELS.ASP,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.asp}</div>
				</SafeCells>
			),
			show: view !== userTypes.TEAM_LEAD,
			maxWidth: 100
		},
		{
			field: FIELDS_KEY.FuelType,
			title: TABLE_LABELS.FuelType,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.fuelType}</div>
				</SafeCells>
			),
			show: view !== userTypes.TEAM_LEAD,
			maxWidth: 110
		},
		{
			field: FIELDS_KEY.OwnerCount,
			title: TABLE_LABELS.OwnerCount,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.ownerCount}</div>
				</SafeCells>
			),
			show: view !== userTypes.TEAM_LEAD,
			maxWidth: 80
		},
		{
			field: FIELDS_KEY.TotalStockedIn,
			title: TABLE_LABELS.TotalStockedIn,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div className='w-100 flex total-stocked'>
						<div className='average'>
							{dataItem?.stockedIn?.average !== null ? dataItem.stockedIn.average : DEFAULT_VALUE}
						</div>
						<div className='current-month'>
							{dataItem?.stockedIn?.currentMonth !== null ? dataItem.stockedIn.currentMonth : DEFAULT_VALUE}
						</div>
					</div>
				</SafeCells>
			),
			maxWidth: 180
		},
		...(callingView ? [{
			title: TABLE_LABELS.ContactPerson,
			field: FIELDS_KEY.ContactPerson,
			cells: ({ dataItem }) => {
				return <SafeCells data={dataItem}>
					<ContactCell dealerName={dataItem?.primaryPersonName} tableRef={tableRef} dealerId={dataItem?.dealerId} isCallOngoing={isCallOngoing} contacts={dataItem?.contactNumbers} setSelectedContact={setSelectedContact} selectedContact={selectedContact} handleCallDealer={handleCallDealer} />
				</SafeCells>
			},
			maxWidth: 150,
		}] : []),

	];

	const handlePageChange = pageNumber => {
		const params = new URLSearchParams();

		params.set('page', (pageNumber.page.skip / pageNumber.page.take) + 1);

		dispatch(setDealerFilterQueryParam(params.toString()));
		dispatch(setSidebarDealerFilterPayloads(null));
		dispatch(setSidebarDealerFilterApplied(null));
		dispatch(setDealerFilterStatus(false));


		history.push({
			pathname: location.pathname,
			search: params.toString()
		});


		setPage({
			skip: pageNumber.page.skip / pageNumber.page.take,
			take: pageNumber.page.take
		});


	}

	const refreshPageData = () => {
		dispatch(fetchMyDealers({
			sortBy: filters.sortBy?.id,
			filterBy: filters.dealerType?.id,
			page: page.skip,
			size: page.take,
			userId: userInfo?.id,
			searchQuery: searchValue,
			userType: userInfo?.type,
			...(isTLView && filters?.filterBy !== MY_DEALERS_TL_VIEW_DEFAULT_FILTER[0] ? { paId: filters.filterBy?.id } : {})
		}, selected === 1)).then((res) => {
			selected === 1 && setCount(res?.pagination?.totalElements)

		}).catch((err) => {
			showToastMessage(err?.error || err?.message, false);
		});
	};

	const handleCallDealer = (dealerId, contacts) => {
		const userId = userInfo?.id;
		const defaultContact = contacts.find(contact => contact.isDefault);

		const callingNumber = defaultContact ? defaultContact.number : contacts[0]?.number;
		dispatch(callDealer({ dealerId, userId, callingNumber })).then((resp) => {
			getCallEvent(resp, EVENT_CAR_SOURCE.dealers)
			dispatch(setCorrelationId(resp?.clientCorrelationId));
			dispatch(setOngoingCallStatus(true));

		}
		);

	}

	const handleSelect = (e) => {
		setSelected(e.selected);
	};


	return (
		<>
			<div className="page-details all-cars page-details-layout">
				<div className="flex-column">
					<OnGoingPais type={CARD_TYPE.dealers} />
					<div className="title-bar">
						{view === userTypes.PROCUREMENT_ASSOCIATE ?
							<TabStrip className='tab-strips' selected={selected} onSelect={handleSelect}>
								<TabStripTab title="My Dealers" onClick={refreshPageData} />
								<TabStripTab title={
									<>
										<span className='tab-title'>Reassigned Dealers</span>
										<span className='reassign-dealer-count'>{count}</span>
									</>
								} />
							</TabStrip> : <span className='title-text'>My Dealers</span>
						}

						<div className="view-all-pais">
							<TablePageInfo currentPage={page.skip} total={dealers?.totalElements} take={page.take} />
							<MyDealersFilters view={view} onSearchChange={setSearchValue} />
						</div>
					</div>
				</div>
				<div className={`app-expandable-table app-table ${view === userTypes.TEAM_LEAD ? 'tl-view-header' : ''}`} ref={tableRef} id="dealer-table">
					<AppTable
						multiSelectEnabled={view === userTypes.TEAM_LEAD}
						admin
						data={dealers?.data}
						skip={page.skip}
						take={page.take}
						headerData={tableColumns}
						isHoverable
						totalCount={dealers?.totalElements}
						handlePageChange={handlePageChange}
						expandable
						wrapperClassName={'dealer-table-wrapper'}
						resetSelection={resetSelection}
						type={CARD_TYPE.dealers}
						onRowSelection={(e) => {
							let marked = [];
							for (const [key, value] of Object.entries(e)) {
								if (value) marked.push(key);
							}
							setSelectedDealers([...marked]);
						}}
						scrollTo={"dealer-table"}
					/>
				</div>
			</div >


			{selectedDealers.length > 0 && <AssignModal setIsAssignModalOpen={setIsAssignModalOpen} setSelectedDealers={setSelectedDealers} filter={filter} setFilter={setFilter} selectedPA={selectedPA} setSelectedPA={setSelectedPA} setDate={setDate} date={date} updateResetSelection={updateResetSelection} resetSelection={resetSelection} />}
			{isAssignModalOpen ? <PaConfirmationModal setSelectedDealers={setSelectedDealers} filter={filter} setIsAssignModalOpen={setIsAssignModalOpen} selectedPA={selectedPA} selectedDealer selectedDealers={selectedDealers} date={date} updateResetSelection={updateResetSelection} resetSelection={resetSelection} /> : null}


			{showShareCarModal && <ShareCarModal close={() => dispatch(shareCar(false))} />}
			{markAsContactedConfirm?.paId && <ConfirmContactedModal />}
			{isLoading && <AppLoader />}
		</>
	);
};

export default MyDealers;
