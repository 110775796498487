import { USER_ROLE_KEYS } from '../../../../utils/constants/menu.constants';
import { userTypes } from '../../../../utils/constants/user-types.constants';
import { ALL_CARS_OCB_LIVE_FILTER } from '../constants/filters.constants';


const teamLeadFilters = [ALL_CARS_OCB_LIVE_FILTER[0].id, ALL_CARS_OCB_LIVE_FILTER[1].id, ALL_CARS_OCB_LIVE_FILTER[3].id];

export const roleAccessMap = {
  [userTypes.PROCUREMENT_ASSOCIATE]: [ALL_CARS_OCB_LIVE_FILTER[0].id, ALL_CARS_OCB_LIVE_FILTER[1].id, ALL_CARS_OCB_LIVE_FILTER[2].id, ALL_CARS_OCB_LIVE_FILTER[3].id, ALL_CARS_OCB_LIVE_FILTER[4].id],
  [USER_ROLE_KEYS.C2B_VIZ_PANEL_NO_LIVE_FEED]: [ALL_CARS_OCB_LIVE_FILTER[2].id, ALL_CARS_OCB_LIVE_FILTER[3].id, ALL_CARS_OCB_LIVE_FILTER[4].id],
  [userTypes.TEAM_LEAD]: teamLeadFilters,
  [userTypes.REGION_HEAD]: teamLeadFilters,
  [userTypes.RELATIONSHIP_MANAGER]: teamLeadFilters,
  [userTypes.DEALERSHIP_MANAGER]: teamLeadFilters,
  [userTypes.TARGET_PRICE_PROCUREMENT_ASSOCIATE]: teamLeadFilters
};



export const hasCallingAccess = (userRoles) => {
  const requiredRoles = [USER_ROLE_KEYS.C2B_VIZ_PANEL_CALLING_VIEW]
  return requiredRoles.some(role => userRoles?.includes(role));
}

const allowedUserTypes = [userTypes.PROCUREMENT_ASSOCIATE];

export const showCallingStatus = (userType) => {
  return allowedUserTypes.includes(userType);
}

export const hasRADetailsAccess = (userRoles) => {
  const requiredRoles = [userTypes.DEALERSHIP_MANAGER, userTypes.RELATIONSHIP_MANAGER];

  return !requiredRoles.some(role => userRoles?.includes(role));
};
