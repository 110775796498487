import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react";
import _debounce from 'lodash/debounce';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextBox } from "@progress/kendo-react-inputs";
import { updateDealersFilters } from "../store/actions/myDealers";
import { MY_DEALERS_TYPE_FILTER, MY_DEALERS_SORT_BY_FILTER, MY_DEALERS_TL_VIEW_FILTER, MY_DEALERS_TL_VIEW_DEFAULT_FILTER } from "../common/constants/filters.constants";
import "./my-dealers.scss";
import { trackAnalytics } from "../common/services/analytics";
import { SegmentEvents } from "../common/constants/events.constants";
import { getLoginUserEmail } from "../../../utils/utils";
import { SEARCH_INPUT_DELAY } from "../common/constants/timeout.constants";
import { userTypes } from "../../../utils/constants/user-types.constants";

const MyDealersFilters = ({ onSearchChange, view }) => {
	const { filters } = useSelector((state) => state.myDealers);
	const email = getLoginUserEmail();
	const dispatch = useDispatch();
	const [search, setSearch] = useState('');
	const { allPAs } = useSelector((state) => state.myDealers);

	const debounceFn = useCallback(_debounce((e) => {
		onSearchChange(e);
	}, SEARCH_INPUT_DELAY), []);

	const handleFilter = (e) => {
		setSearch(e.target.value);
	}

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			debounceFn(e.target.value);
		}
	};

	const handleSearch = () => {
		debounceFn(search);
	};

	const SearchIcon = () => (
		<span onClick={handleSearch} className="k-icon k-font-icon k-i-search search-icon"></span>
	)

	const ClearIcon = () => (
		<span onClick={() => {
			if (search !== '') {
				setSearch('');
				onSearchChange('');
			}
		}} className="k-icon k-font-icon k-i-close clear-icon"></span>
	)

	return (
		<>
			<div className="all-cars-filters">
				<span className="dropdown-text">{view === userTypes.TEAM_LEAD ? "" : "Sort By:"}</span>
				<div className="app-dropdown-container">
					{view === userTypes.TEAM_LEAD ? <DropDownList
						className="app-dropdown tl-view-dropdown"
						textField="email"
						data={[
							MY_DEALERS_TL_VIEW_DEFAULT_FILTER[0],
							...allPAs
						]}
						value={
							filters.filterBy || MY_DEALERS_TL_VIEW_DEFAULT_FILTER[0]
						}
						onChange={(e) => {
							dispatch(updateDealersFilters({ filterBy: e.value }));
						}}
					/> : <DropDownList
						className="app-dropdown"
						data={MY_DEALERS_SORT_BY_FILTER}
						textField="label"
						value={filters.sortBy}
						onChange={(e) => {
							dispatch(updateDealersFilters({ sortBy: e.value }));
							trackAnalytics(SegmentEvents.Viz_sorted_by, {
								email_id: email,
								sorted_by: e.value?.label
							});
						}}
					/>
					}
				</div>


				<div className="app-dropdown-container">
					<DropDownList
						className="app-dropdown"
						data={MY_DEALERS_TYPE_FILTER}
						textField="label"
						value={filters?.dealerType}
						onChange={(e) => {
							dispatch(updateDealersFilters({ dealerType: e.value }));
						}}
					/>
				</div>



				<TextBox
					size="medium"
					fillMode="solid"
					rounded="medium"
					className="app-search-input"
					placeholder="Search dealer by name...."
					prefix={SearchIcon}
					suffix={ClearIcon}
					value={search}
					onChange={handleFilter}
					onKeyDown={handleKeyDown}
				/>
			</div>
		</>
	);
};

export default MyDealersFilters;
