
export default (api) => {
  const getPAIs = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/pai` + params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


  const getAllCars = (params, filters = null) => {
    return new Promise((resolve, reject) => {
      let apiUrl = `/web/v1/appointments` + params;
      api
        .post(apiUrl, filters ? filters : {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDealerCars = (dealerId, params, filters) => {

    return new Promise((resolve, reject) => {
      let apiUrl = `/web/v1/dealers/${dealerId}/appointments` + params;
      api
        .post(apiUrl, filters ? filters : {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


  const fetchSideBarFiltersData = () => {
    return new Promise((resolve, reject) => {
      api
        .get('/web/v1/catalog/filters')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


  const fetchRecoDealers = (appointmentId, params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/appointments/${appointmentId}/reco-dealers` + params,)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const geCurrentBids = (appointmentId, reqParams) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/appointments/${appointmentId}/bids` + reqParams,)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });

    });
  };

  const getMyDealers = (params, isReassigned) => {
    const url = `/web/v1/${isReassigned ? 'reassigned-dealers' : 'dealers'}${params}`;
    return api.get(url)
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  };

  const getUserInfo = (email) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/users/${email}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCarDetails = (appointmentId, params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/appointments/` + appointmentId + params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getInspectionPDF = (carId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/inspection/report?carId=` + carId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getRADetails = (appointmentId, userId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/appointments/${appointmentId}/ra-detail`, {
          params: userId
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const markDealerContacted = (params) => {
    return new Promise((resolve, reject) => {
      api
        .put(`/web/v1/dealers/contacted${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const shareCars = (cars) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/web/v1/catalog/share-with-dealer`, cars)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addDealerContact = (params) => {
    const { dealerId, newNumber } = params;
    return new Promise((resolve, reject) => {
      api
        .post(`/web/v1/dealers/${dealerId}/contacts`, { contactNumber: newNumber })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });

    })
  }

  const deleteDealerContact = (params) => {
    const { dealerId, contactNumber } = params;
    return new Promise((resolve, reject) => {
      api
        .delete(`/web/v1/dealers/${dealerId}/contacts`, {
          params: {
            contactNumber
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };



  const updateDealerContact = (params) => {
    const { dealerId, existingContactNumber, newContactNumber } = params;
    return new Promise((resolve, reject) => {
      api
        .put(`/web/v1/dealers/${dealerId}/contacts`, { existingContactNumber, newContactNumber })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });

    })

  }

  const setDefaultDealerNumber = (params) => {
    const { dealerId, contactNumber } = params;
    return new Promise((resolve, reject) => {
      api
        .patch(`/web/v1/dealers/${dealerId}/contacts`, { contactNumber })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });

    })
  }

  const callDealer = (params) => {
    const { dealerId, userId, callingNumber } = params;
    return new Promise((resolve, reject) => {
      api
        .post(`/web/v1/dealers/${dealerId}/call`, { userId, destinationNumber: callingNumber })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const callLogsData = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/call-logs`, { params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const lastEventDetails = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/call-logs/last-call-event`, { params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  const getReassignPA = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/users/basic-info`, {
          params: {
            tlId: params
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const reassignDealer = (params) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/web/v1/reassigned-dealers`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    }
    )
  }

  const getNotificationData = (params) => {

    const { id, ...rest } = params;

    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/notifications/${id}`,
          {
            params: {
              ...rest,
              sort: 'isSeen,asc'
            }
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const markNotificationAsImportant = (params) => {
    const { notificationId, isImportant, receiverId } = params;

    return new Promise((resolve, reject) => {
      api
        .patch(`/web/v1/notifications/${notificationId}`, null, {
          params: {
            isImportant,
            receiverId
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  const getImportantNotificationCount = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/notifications/${params.id}/count`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const markNotificationAsSeen = (params) => {

    return new Promise((resolve, reject) => {
      api
        .put(`/web/v1/notifications/seen?receiverId=${params.id}`, {
          notificationIds: params.notificationIds
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        }
        );
    }
    )
  }

  const getAllLogs = (params) => {

    const { page, size, userId } = params;
    return new Promise((resolve, reject) => {
      api
        .get(`/web/v1/uploads/logs`, {
          params: {
            userId,
            page,
            size
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  }

  const getPresignedURL = (params) => {

    const { uploadType } = params;

    return new Promise((resolve, reject) => {
      api
        .post('/web/v1/uploads/presigned-url', {
          uploadType,
          urlType: "GET_SAMPLE_FILE"
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  }

  const getDropDownData = () => {
    return new Promise((resolve, reject) => {
      api
        .get('/web/v1/uploads/upload-type')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const uploadFile = (params) => {

    const { uploadType } = params;

    return new Promise((resolve, reject) => {
      api
        .post('/web/v1/uploads/presigned-url', {
          uploadType,
          urlType: "UPLOAD_FILE"
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const putUploadFile = (params) => {
    const { presignedUrl, binaryFile } = params;
    
    return new Promise((resolve, reject) => {
      api
        .put(presignedUrl, {
          binaryFile
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const uploadFileToS3 = (params) => {
    const { userId, ...rest } = params;

    return new Promise((resolve, reject) => {
      api
        .post(`/web/v1/uploads`, rest, {
          params: {
            userId
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  };

  const downloadFile = (params) => {
    const { uploadType, filename } = params;

    return new Promise((resolve, reject) => {
      api
        .post('/web/v1/uploads/presigned-url', {
          filename,
          uploadType,
          urlType: "GET_PROCESSED_FILE"
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }



  return {
    getPAIs,
    getAllCars,
    getDealerCars,
    fetchRecoDealers,
    geCurrentBids,
    getMyDealers,
    getUserInfo,
    getCarDetails,
    getInspectionPDF,
    getRADetails,
    markDealerContacted,
    fetchSideBarFiltersData,
    shareCars,
    addDealerContact,
    deleteDealerContact,
    updateDealerContact,
    setDefaultDealerNumber,
    callDealer,
    callLogsData,
    lastEventDetails,
    getReassignPA,
    reassignDealer,
    getNotificationData,
    getImportantNotificationCount,
    markNotificationAsImportant,
    markNotificationAsSeen,
    getAllLogs,
    getPresignedURL,
    getDropDownData,
    uploadFile,
    putUploadFile,
    uploadFileToS3,
    downloadFile
  };
};
