import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Loader } from "@progress/kendo-react-indicators";

import "./tab-current-bids.scss";
import AppTable from "../app-table";
import { vizPanelService } from "../../../services";
import { currencyFormat, getDateTime, getPaId, getReqParams } from "../../utils/utils";
import { CURRENT_BIDS_API_POLLING } from "../../constants/timeout.constants";

const TabCurrentBids = ({ appointmentId, setHasActiveBid }) => {
	const [data, setData] = useState([]);
	const [prevBids, setPrevBids] = useState([]);
	const [firstCall, setFirstCall] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const { userInfo } = useSelector((state) => state.allCars);

	useEffect(() => {
		refresh();
	}, [appointmentId]);

	useEffect(() => {
		setFirstCall(true);
		const refreshInterval = setInterval(() => {
			refresh();
		}, CURRENT_BIDS_API_POLLING);
		return () => {
			clearInterval(refreshInterval);
		};
	}, []);

	const refresh = () => {
		if (appointmentId && userInfo?.id) {
			getCurrentBids();
		}
	};

	const getCurrentBids = () => {
		let reqParams = getReqParams({ paId: getPaId(userInfo), page: 0, size: 100 });
		vizPanelService.geCurrentBids(appointmentId, reqParams)
			.then((res) => {
				let bids = res?.data ? res?.data : [];
				setData(bids);
				if (bids && bids?.length !== prevBids?.length && !firstCall) {
					setHasActiveBid(true);
					setPrevBids(bids);
				} else {
					setPrevBids(bids);
				}
				setFirstCall(false);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	const SafeCells = (safeCellProps) => {
		const event = {
			dataItem: safeCellProps.data
		};
		return (
			<td onClick={() => navigateTo(event)} className={safeCellProps?.className}>
				{safeCellProps.children}
			</td>
		);
	};

	const maskString = (str) => {
		if (!str) return '';
		return '*'.repeat(str.length);
	};


	const tableColumns = [
		{
			field: 'label',
			title: 'label',
			cells: ({ dataItem }) => {
				const displayDealerName = !dataItem?.dealerDetails?.hideDealerDetails ? dataItem?.dealerDetails?.dealerName : maskString(dataItem?.dealerDetails?.dealerName);
				return (
					<SafeCells data={dataItem} className="highlight-label">
						<div className="grid-label">{displayDealerName}</div>
					</SafeCells>
				);
			},
		},
		{
			field: 'amount',
			title: 'amount',
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div className="grid-value">{currencyFormat(dataItem?.bidDetails?.bidAmount)}</div>
				</SafeCells>
			),
			maxWidth: 150
		},
		{
			title: 'time',
			field: 'time',
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div className="grid-value">
						{getDateTime(dataItem?.bidDetails?.bidTime)}
					</div>
				</SafeCells>
			),
			minWidth: 150
		},
		{
			field: 'contactNumber',
			title: 'contactNumber',
			cells: ({ dataItem }) => {
				const displayPrimaryContact = !dataItem?.dealerDetails?.hideDealerDetails ? dataItem?.dealerDetails?.contactNumber?.primary : maskString(dataItem?.dealerDetails?.contactNumber?.primary);
				const displaySecondaryContact = !dataItem?.dealerDetails?.hideDealerDetails ? dataItem?.dealerDetails?.contactNumber?.secondary : maskString(dataItem?.dealerDetails?.contactNumber?.secondary);
				return (
					<SafeCells data={dataItem}>
						<div className="grid-value">
							<div>{displayPrimaryContact}</div>
							<div>{displaySecondaryContact}</div>
						</div>
					</SafeCells>
				);
			},
			minWidth: 180
		}
	];


	return (
		<div className="tab-current-bids">
			{isLoading ? (
				<div className="loader-container">
					<Loader type="infinite-spinner" size="medium" />
				</div>
			) :
				<div className="current-bids-grid app-table-view-sm app-table-hide-header table-as-grid">
					<AppTable
						data={data}
						headerData={tableColumns}
						isHoverable={false}
						totalCount={data.length}
						handlePageChange={() => { }}
						pageable={false}
						take={data?.length}
					/>
				</div>
			}
		</div>
	);
};

export default TabCurrentBids;
