import {
	ALL_CARS_OCB_LIVE_FILTER, ALL_CARS_SORT_BY_FILTER,
	getAppliedFilters,
	getSelectedCenterValues,
	getSelectedStateValues
} from "../common/constants/filters.constants";
import "./all-cars.scss";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useDispatch, useSelector } from "react-redux";
import { setCenterCheckedItems, setFilterQueryParam, setFilterStatus, setSidebarFilteApplied, setSidebarFilterPayloads, updateCarsFilters } from "../store/actions/allCars";
import { TextBox } from "@progress/kendo-react-inputs";
import { SEARCH_INPUT_DELAY } from "../common/constants/timeout.constants";
import { memo, useCallback, useEffect, useState } from "react";
import _debounce from 'lodash/debounce';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SideBarFilters from "./side-bar-filters";
import { getUserRoles } from "../../../utils/utils";
import { roleAccessMap } from "../common/utils/viz-roles";
import { buildQueryParam } from "../common/utils/side-bar-filter";

const AllCarsFilters = ({ applied, clearMoreFilters, queryParameters }) => {

	const { filters, isFilterApplied, sidebarFiltersApplied, sidebarFilters, checkedCenterItems, userInfo } = useSelector((state) => state.allCars);
	useSelector((state) => state.allCars);

	const [checkedItems, setCheckedItems] = useState(checkedCenterItems);
	const [search, setSearch] = useState(queryParameters['searchQuery'] || '');

	const debounceFn = useCallback(_debounce((e) => dispatch(updateCarsFilters({ searchAllCarQuery: e })), SEARCH_INPUT_DELAY), []);
	const [showMoreFilters, setShowMoreFilters] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();


	useEffect(() => {
		dispatch(setCenterCheckedItems(checkedItems));
	}, [checkedItems])


	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const ocbLive = params.get('category');
		const sortBy = params.get('sortBy');
		const searchQuery = params.get('searchQuery');

		if (ocbLive) {
			const ocbLiveFilter = ALL_CARS_OCB_LIVE_FILTER.find(filter => filter.id === ocbLive);
			if (ocbLiveFilter) {
				dispatch(updateCarsFilters({ ocbLive: ocbLiveFilter }));

			}
		}

		if (sortBy) {
			const sortByFilter = ALL_CARS_SORT_BY_FILTER.find(filter => filter.id === sortBy);
			if (sortByFilter) {
				dispatch(updateCarsFilters({ sortBy: sortByFilter }));
			}
		}

		if (searchQuery) {
			dispatch(updateCarsFilters({ searchAllCarQuery: searchQuery }))
		}


	}, []);


	const handleFilter = (e) => {
		setSearch(e.target.value)
	}

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			const params = new URLSearchParams(window.location.search);
			debounceFn(e.target.value);

			if (e.target.value.length > 0) {
				params.set("searchQuery", e.target.value);
			} else {
				params.delete("searchQuery");
			}
			dispatch(setFilterQueryParam(params.toString()));
			history.push({ pathname: window.location.pathname, search: params.toString() });
		}
	};


	const handleSearch = () => {
		debounceFn(search);
	};

	const SearchIcon = () => {
		return (<>
			<span onClick={handleSearch} className="k-icon k-font-icon k-i-search search-icon"></span>
		</>)
	}

	const ClearIcon = () => {
		return (<>
			<span onClick={() => {
				if (search !== '') {
					const params = new URLSearchParams(window.location.search);
					setSearch('');
					dispatch(updateCarsFilters({ searchAllCarQuery: '' }))
					params.delete("searchQuery");
					history.push({ pathname: window.location.pathname, search: params.toString() });

				}
			}} className="k-icon k-font-icon k-i-close clear-icon"></span>
		</>)
	}

	const isDisabled = filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[2].id || filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3].id || filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[4].id;

	const placeholderText = filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[2].id || filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3].id || filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[4].id
		? "Search cars by appt id...."
		: "Search cars by name....";

	const handleDropdownChange = (paramKey, e) => {
		const newValue = e.value;


		const params = new URLSearchParams(window.location.search);

		if (paramKey === 'ocbLive') {
			params.set('category', newValue.id);
		}
		else {
			params.set(paramKey, newValue.id);
		}

		dispatch(updateCarsFilters({ [paramKey]: newValue }));
		dispatch(setFilterQueryParam(params.toString()));
		history.push({ pathname: window.location.pathname, search: params.toString() });
	};

	const applyCarFilters = (filterOptions) => {

		let carFilters = getAppliedFilters(filterOptions);
		let registrationStates = { states: getSelectedStateValues(filterOptions[filterOptions.length - 3]) };
		let stores = getSelectedCenterValues(filterOptions[0]);
		const params = buildQueryParam({ filterOptions, search, filters })


		let reqpayload = {
			carFilters,
			registrationStates,
			stores
		};

		if (carFilters.length > 0 || registrationStates.states.length > 0 || stores.length > 0) {
			dispatch(setFilterStatus(true));
		}
		else {
			dispatch(setFilterStatus(false));
		}

		dispatch(setSidebarFilterPayloads(reqpayload));
		dispatch(setSidebarFilteApplied([...filterOptions]));
		setShowMoreFilters(false);
		dispatch(setFilterQueryParam(params.toString()));

		history.push({ pathname: window.location.pathname, search: params.toString() });

	}

	const resetCarFilters = () => {

		dispatch(setSidebarFilteApplied(null));
		dispatch(setSidebarFilterPayloads(null));
		dispatch(setFilterStatus(false));
		dispatch(setFilterQueryParam(''));
		setCheckedItems([]);
		setSearch('')
		history.push({ pathname: window.location.pathname, search: '' });

	}

	const getFilteredDropdownData = (userType, data) => {
		return roleAccessMap[userType] ? data.filter(item => roleAccessMap[userType].includes(item.id)) : data;

	}

	const filteredDropdownData = getFilteredDropdownData(userInfo?.type, ALL_CARS_OCB_LIVE_FILTER);

	return (
		<>
			{showMoreFilters && <SideBarFilters checkedItems={checkedItems} setCheckedItems={setCheckedItems} applyCarFilters={applyCarFilters} filters={filters} sidebarFiltersApplied={sidebarFiltersApplied} sidebarFilters={sidebarFilters} queryParameters={queryParameters} close={() => { setShowMoreFilters(false) }} clearMoreFilters={clearMoreFilters} applied={applied} resetCarFilters={resetCarFilters} />}

			<div className="all-cars-filters">
				<div className="app-dropdown-container">
					<DropDownList
						className="app-dropdown"
						data={filteredDropdownData}
						textField="label"
						value={filters.ocbLive}
						onChange={(e) => handleDropdownChange('ocbLive', e)}
					/>
				</div>

				<span
					className="dropdown-text"
				>
					Sort By:
				</span>
				<div className="app-dropdown-container">
					<DropDownList
						className="app-dropdown"
						data={ALL_CARS_SORT_BY_FILTER}
						textField="label"
						value={filters.sortBy}
						onChange={(e) => handleDropdownChange('sortBy', e)}
					/>
				</div>

				<TextBox
					size="medium"
					fillMode="solid"
					rounded="medium"
					className="app-search-input"
					placeholder={placeholderText}
					prefix={SearchIcon}
					suffix={ClearIcon}
					value={search}
					onChange={handleFilter}
					onKeyDown={handleKeyDown}
				/>
				<span
					className={`filter-btn-primary ${isFilterApplied ? 'filters-applied-icon' : ''} ${isDisabled ? 'disabled' : ''}`}
					onClick={!isDisabled ? () => setShowMoreFilters(true) : undefined}
				>
					<span className='k-icon k-font-icon k-i-filter'></span>
				</span>
			</div>


		</>
	);
};
export default memo(AllCarsFilters);
