import { ALL_CARS_TABLE_VIEW } from "./table.constants";

export const ALL_CARS_BIDS_FILTER = [
    { id: 'All', label: 'All' },
    { id: 'No Bid', label: 'No Bid' },
    { id: 'Req Price/TP >95%', label: 'Req Price/TP >95%' },
]

export const ALL_CARS_AUCTION_FILTER = [
    { id: 'Auction Cars', label: 'Auction Cars' },
    { id: '1st auction only', label: '1st auction only' },
    { id: 'Other auctions', label: 'Other auctions' },
];

export const ALL_CARS_OCB_LIVE_FILTER = [
    { id: 'ocb', label: 'OCB' },
    { id: 'live', label: 'LIVE' },
    { id: 'nego', label: 'In Nego' },
    { id: 'any', label: 'All' },
    { id: 'procured', label: 'Procured' },
];

export const ALL_CARS_TYPE_FILTER = [
    { id: 'All', label: 'Type' },
    { id: '1st auction only', label: '1st auction only' },
    { id: 'Other auctions', label: 'Other auctions' },
];
export const ALL_CARS_SORT_BY_FILTER = [
    { id: 'newestFirst', label: 'Newest First' },
    { id: 'oldestFirst', label: 'Oldest First' },
];

export const MY_DEALERS_TYPE_FILTER = [
    { id: 'all', label: 'All' },
    { id: 'active', label: 'Transacting' },
    { id: 'inactive', label: 'Non-Transacting' },

];

export const MY_DEALERS_TL_VIEW_FILTER = [
    { id: 'all', label: 'All' },
    { id: 'active', label: 'Transacting' },
    { id: 'inactive', label: 'Non-Transacting' },

]

export const MY_DEALERS_TL_VIEW_DEFAULT_FILTER = [
    { id: 'filterByPA', value: null, email: 'Filter By PA' },
    { id: 'reassignPA', value: null, email: 'Select PA to reassign' },
];

export const MY_DEALERS_SORT_BY_FILTER = [
    { id: 'name', label: 'Name' },
    { id: 'status', label: 'Status' },
];

export const MY_DEALERS_CARS_VIEW_FILTER = [
    { id: ALL_CARS_TABLE_VIEW.dealersOCBView, label: 'OCB' },
    { id: ALL_CARS_TABLE_VIEW.dealersAuctionView, label: 'Auction' },
    { id: ALL_CARS_TABLE_VIEW.dealersNegoView, label: 'In Nego' },
    { id: ALL_CARS_TABLE_VIEW.dealersProcuredView, label: 'Procured' },
];
export const MY_DEALERS_CARS_SORT_FILTER = [
    { id: 'reco', label: 'Reco Info' },
    { id: 'endingSoonest', label: 'Ending Soonest' },
    { id: 'newestAuction', label: 'Newest Auction' },
];

export const ALL_PAIS_SORT_BY_FILTER = [
    { id: 'minTimeLeft', label: 'Min time left' },
    { id: 'maxTimeLeft', label: 'Max time left' },
];

const getSelectedValues = (ele) => {
    let val = [];
    (ele || []).map(field => {
        val.push(field.filter.value)
    });
    return val;
}

const getSelectedMakeModel = (ele, isMake, filterOptions) => {
    let val = [];
    let index = filterOptions.findIndex(option => option.id === "MAKE_MODEL");
    filterOptions[index]?.selected?.map(selectedOption => {
        let reqVal = selectedOption?.filter?.value + ":";
        if (!isMake && ele && ele?.length > 0) {
            let children = selectedOption?.children || [];
            let choosen = '';
            children.map(modelOptions => {
                let found = ele?.findIndex(selectedEleOption => selectedEleOption.display === modelOptions.display);
                if (found > -1) {
                    choosen = choosen ? choosen + ',' + ele[found].filter.value : ele[found].filter.value;
                }
            });
            reqVal = reqVal + choosen;
        }
        val.push(reqVal)
    })
    return val;
}

const getSelectedRangeValues = (ele) => {
    return ele ? [ele.start, ele.end] : [];
}

export const getSelectedStateValues = (ele) => {
    let val = [];
    (ele?.selected || []).map(field => {
        val.push(field)
    });
    return val;
}

export const getSelectedCenterValues = (ele) => {
    let val = [];
    (ele?.selected || []).map(field => {
        val.push(field)
    });
    return val;
}


export const getAppliedFilters = (filterOptions) => {
    let data = [];
    filterOptions.map(each => {
        let curentFilter = {
            key: '',
            values: []
        }
        switch (each.id) {
            case "INSPECTION_CITY":
                curentFilter.key = "inspectionCity";
                curentFilter.values = getSelectedValues(each.selected);
                break;
            case "Model":
                curentFilter.key = "makeModel";
                curentFilter.values = getSelectedMakeModel(each.selected, false, filterOptions);
                break;
            case "YEAR":
                curentFilter.key = "year";
                curentFilter.values = getSelectedRangeValues(each.selected);
                break;
            case "ODOMETER":
                curentFilter.key = "odometer";
                curentFilter.values = getSelectedRangeValues(each.selected);
                break;
            case "FUEL_TYPE":
                curentFilter.key = "fuelType";
                curentFilter.values = getSelectedValues(each.selected);
                break;
        }
        if (curentFilter.values?.length > 0) { data.push(curentFilter) }
    })

    return data;
}

export const queryParamsKeys = [
    'storecityid',
    'storeid',
    'inspection_city',
    'make_model',
    'model',
    'fuel_type',
    'registrationstates',
    'year',
    'odometer',
];

export const CALENDAR_CONSTANTS = [
    { id: 'Today', label: 'Today' },
    { id: 'Yesterday', label: 'Yesterday' },
    { id: 'Last 7 days', label: 'Last 7 days' },
    { id: 'Last 30 days', label: 'Last 30 days' },
    { id: 'Custom', label: 'Custom' },
];

export const TL_VIEW_CALENDAR = [
    { id: '0', label: 'Select Duration' },
    { id: '1', label: '1 Day' },
    { id: '2', label: '2 Days' },
    { id: 'custom', label: 'Custom' },
]



