import { OverallConversationTimeHeader, TimeHeader, TotalStockedInHeader } from "../components/table-header";

export const FIELDS_KEY = {
  CAR_BASIC_INFO: "carsBasicInfo",
  CAR_HIGHLIGHTS: "carHighlights",
  RECOMMENDED_DEALERS: "dealerTags",
  ACTION: "action",
  HighestBid: "highestBid ",
  TargetPrice: "targetPrice",
  HBTP: 'HBTP',
  AuctionType: 'auctionType',
  CarAge: 'carAge',
  KMDriven: 'KMDriven',
  BodyType: 'BodyType',
  ASP: 'ASP',
  FuelType: 'FuelType',
  OwnerCount: 'OwnerCount',
  TotalStockedIn: 'TotalStockedIn',
  expanded: 'expanded',
  DealersInfo: "dealersInfo",
  DealersCode: "dealersCode",
  DealerName: 'dealerName',
  Status: 'status',
  Contacted: 'contacted',
  Provider: 'provider',
  ContactPerson: 'ContactPerson',
  MarkAsContacted: 'MarkAsContacted',
  PreviousHB: 'PreviousHB',
  ReqPriceTP: 'ReqPriceTP',
  DealersName: "DealersName",
  RequestedPrice: "RequestedPrice",
  HighestNegoPrice: 'HighestNegoPrice',
  TypeOfDealer: 'TypeOfDealer',
  CompanyName: "CompanyName",
  FinalNegoPrice: 'FinalNegoPrice',
  Date: 'Date',
  Time: 'Time',
  Number: 'Number',
  CallType: 'CallType',
  OverallCallStatus: 'OverallCallStatus',
  HangupCause: 'HangupCause',
  CallerStatus: 'CallerStatus',
  OverallConversationTime: 'OverallConversationTime',
  CallerName: 'CallerName',
  Recording: 'Recording',
  YardDetails: 'YardDetails',
  PaName: 'PaName',
  PaEmail: 'PaEmail',
  OcbPrice: 'OcbPrice',
  UploadedOn: 'UploadedOn',
  FileName: 'FileName',
  FileStatus: 'FileStatus',
  Comment: 'Comment',
};

export const TABLE_LABELS = {
  CAR_BASIC_INFO: "Car's Basic Info",
  CAR_HIGHLIGHTS: "Car Highlights",
  RECOMMENDED_DEALERS: "Recommended Dealers",
  ACTION: "Action",
  HighestBid: "Highest Bid ",
  TargetPrice: "Target Price (TP)",
  HBTP: 'HB/TP',
  AuctionType: 'Auction Type',
  CarAge: 'Car Age',
  KMDriven: 'KM Driven',
  BodyType: 'Body Type',
  ASP: 'ASP',
  FuelType: 'Fuel Type',
  OwnerCount: 'Owner Count ',
  TotalStockedIn: <TotalStockedInHeader />,
  expanded: 'expanded',
  DealersInfo: "Dealer's Info",
  DealerName: "Dealer's Name",
  DealersCode: "Dealer's Code",
  Status: "Status",
  Contacted: 'Contacted',
  Provider: 'Unnati Applicable',
  ContactPerson: 'Contact Person',
  MarkAsContacted: 'Mark As \nContacted',
  PreviousHB: 'Previous HB',
  ReqPriceTP: 'Req Price/TP',
  DealersName: "Dealer's Name",
  RequestedPrice: 'Requested Price',
  HighestNegoPrice: 'Highest Nego Price',
  TypeOfDealer: 'Type Of Dealer',
  CompanyName: "Company Name",
  FinalNegoPrice: 'Final Negotiated Price',
  YardDetails: 'Yard Details',
  Date: 'Date',
  Time: <TimeHeader />,
  Number: 'Number',
  CallType: 'Call Type',
  OverallCallStatus: 'Overall Call Status',
  HangupCause: 'Hangup Cause',
  CallerStatus: 'Caller Status',
  OverallConversationTime: <OverallConversationTimeHeader />,
  CallerName: 'Caller Name',
  Recording: 'Recording',
  PaName: 'PA Name',
  PaEmail: 'PA Email',
  OcbPrice: 'Ocb Price',
  UploadedOn: 'Uploaded On',
  FileName: 'File Name',
  FileStatus: 'File Status',
  Comment: 'Comment',
};

export const INITIAL_TABLE_SETTINGS = {
  skip: 0,
  take: 10
};

export const ALL_CARS_TABLE_VIEW = {
  all: "all-Cars",
  dealersOCBView: "ocb",
  dealersNegoView: "nego",
  dealersAuctionView: "live",
  dealersProcuredView: "procured",
}

export const CARD_TYPE = {
  cars: "cars",
  pais: "pais",
  dealers: "dealers",
}

export const CAR_DETAILS_TAB_LIST = [
  { id: 1, label: "Dealers" },
  { id: 2, label: "RFC Details" },
  { id: 3, label: "Car Details" },
  { id: 4, label: "Current Bids" },
  { id: 5, label: "Unlock RA Details" },
];


export const EVENT_CAR_SOURCE = {
  cars: "All cars",
  pais: "PAI",
  dealers: "My Dealers",
  callLogs: "Call Logs",
}

export const CONTACT_STATUS = {
  CONTACTED: 'Contacted',
  ATTEMPTED: 'Attempted',
  NOT_CONTACTED: 'Not Contacted'
}