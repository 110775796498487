import { useEffect, useRef, useState } from 'react';
import "../common/assets/styles/common.scss";
import AppTable from "../common/components/app-table";
import AppTableCell from "../common/components/app-table-cell";
import { INITIAL_TABLE_SETTINGS, FIELDS_KEY, TABLE_LABELS, ALL_CARS_TABLE_VIEW, CARD_TYPE, EVENT_CAR_SOURCE } from "../common/constants/table.constants";
import AllCarsFilters from "./all-cars-filters";
import DealersViewFilters from "./dealers-view-filters";
import { useDispatch, useSelector } from "react-redux";
import "./all-cars.scss";
import { fetchAllCars, fetchDealerCars, fetchSideBarFiltersData } from "../store/actions/allCars";
import { setDealerID } from "../store/actions/myDealers";
import { getLoginUserEmail } from "../../../utils/utils";
import OnGoingPais from "../all-pais/on-going-pais";
import { AppLoader } from '../common/components/app-loader';
import TablePageInfo from '../common/components/table-page-info';
import ShareCarButton from '../common/components/share-car-modal/share-button';
import { trackAnalytics } from '../common/services/analytics';
import { getSessionToken } from '@descope/react-sdk';
import { SegmentEvents } from '../common/constants/events.constants';
import CarDetails from '../car-details';
import { ALL_CARS_OCB_LIVE_FILTER, queryParamsKeys } from '../common/constants/filters.constants';
import { generateTrackData, getCurrentType, getPaId, queryStringToObject } from '../common/utils/utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setInitialFilters, updateFilterQueryParam, updateHistory } from '../common/utils/side-bar-filter';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { APPOINTMENT_STATES } from '../common/constants/fields.constants';

const AllCars = ({ view = ALL_CARS_TABLE_VIEW.all, dealerId = null }) => {
	const CustomCell = cellProps => (
		<AppTableCell
			{...cellProps}
			type={view === ALL_CARS_TABLE_VIEW.all ? CARD_TYPE.cars : CARD_TYPE.dealers}
			dealerId={dealerId}
			logAnalytic={logAnalytic}
		/>
	);

	const [page, setPage] = useState(INITIAL_TABLE_SETTINGS);
	const [dealerCarCategory, setDealerCarView] = useState(ALL_CARS_TABLE_VIEW.all);
	const dispatch = useDispatch();
	const carsData = useSelector((state) => state.allCars);
	const isModal = useSelector((state) => state.allCars?.isModal);
	const allCarsData = useSelector((state) => state.allCars?.data);
	const { filters, userInfo, sidebarFiltersPayload, isPaiCar, sidebarFilters, filterQueryParam, isLoading } = useSelector((state) => state.allCars);
	const { sideBarDealerFilterPayload, clientCorrelationId, dealerFilterQueryParam } = useSelector((state) => state.myDealers);
	const history = useHistory();
	const queryParameters = queryStringToObject(window.location.search);
	const [selected, setSelected] = useState(0);
	const [anyCarsData, setAnyCarsData] = useState();
	const [exclusiveCarCount, setExclusiveCarCount] = useState(0);

	const isExclusiveFilterActive = filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3].id && filters?.searchAllCarQuery;

	const columns = [
		{
			field: FIELDS_KEY.CAR_BASIC_INFO,
			title: TABLE_LABELS.CAR_BASIC_INFO,
			cells: CustomCell,
			show: true,
			maxWidth: 620
		},
		{
			field: FIELDS_KEY.CAR_HIGHLIGHTS,
			title: TABLE_LABELS.CAR_HIGHLIGHTS,
			cells: CustomCell,
			show: true,
			minWidth: 125,
		},
		{
			title: TABLE_LABELS.RECOMMENDED_DEALERS,
			field: FIELDS_KEY.RECOMMENDED_DEALERS,
			cells: CustomCell,
			show: view === ALL_CARS_TABLE_VIEW.all && filters?.ocbLive?.id !== ALL_CARS_OCB_LIVE_FILTER[2].id && filters?.ocbLive?.id !== ALL_CARS_OCB_LIVE_FILTER[4].id,
			minWidth: 150,
		},
		{
			title: TABLE_LABELS.DealersInfo,
			field: FIELDS_KEY.DealersInfo,
			cells: CustomCell,
			show: view !== ALL_CARS_TABLE_VIEW.all && (
				filters.dealerCarCategory.id === ALL_CARS_TABLE_VIEW.dealersOCBView ||
				filters.dealerCarCategory.id === ALL_CARS_TABLE_VIEW.dealersAuctionView
			),
			minWidth: 150,
		},
		{
			title: TABLE_LABELS.Status,
			field: FIELDS_KEY.Status,
			cells: CustomCell,
			show: (view !== ALL_CARS_TABLE_VIEW.all && (filters.dealerCarCategory.id === ALL_CARS_TABLE_VIEW.dealersNegoView || filters.dealerCarCategory.id === ALL_CARS_TABLE_VIEW.dealersProcuredView)) ||
				(view === ALL_CARS_TABLE_VIEW.all && (filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[2].id || filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[4].id)),
			minWidth: 150,
			maxWidth: 200
		},
		{
			field: FIELDS_KEY.ACTION,
			title: TABLE_LABELS.ACTION,
			cells: CustomCell,
			show: true,
			minWidth: 200,
			maxWidth: 200
		}
	];
	const [tableColumns, setTableColumns] = useState([...columns]);
	const isInitialPageLoad = useRef(true);
	const pageRefreshState = useRef(false);

	useEffect(() => {
		setTableColumns([...columns]);
	}, [clientCorrelationId]);

	const logAnalytic = (cardetails) => {
		const email = getLoginUserEmail();
		const session_id = getSessionToken();

		let eventName = SegmentEvents.Viz_impression;
		let trackData = {
			email_id: email,
			source: dealerId ? EVENT_CAR_SOURCE.dealers : EVENT_CAR_SOURCE.cars,
			dealer_id: dealerId || null,
			auction_id: null,
			appointment_id: null,
			sorting_rank: dealerId ? filters?.dealerCarSort?.label : filters?.sortBy?.label,
			rec_score: '',
			session_id: session_id,
			auctionType: dealerId ? filters?.dealerCarCategory?.label : filters?.ocbLive?.label,
			askPrice: '',
			client_correlation_id: clientCorrelationId
		};


		if (cardetails) {
			trackData.appointment_id = cardetails?.appointmentDetails?.appointmentId;
			trackData.auction_id = cardetails?.ocbDetails?.auctionId || cardetails?.liveAuctionDetails?.auctionId || cardetails?.negoDetails?.auctionId;
			trackData.requestedPrice = cardetails?.ocbDetails?.requestedPrice || cardetails?.liveAuctionDetails?.requestedPrice || null;
			trackData.rec_score = cardetails?.recoScore;
		} else {
			if (carsData?.data?.length < 1) return;
			const appointments = carsData?.data.map(ele => ({
				appointment_id: ele?.appointmentDetails?.appointmentId,
				recoScore: ele?.recoScore,
				carId: ele?.appointmentDetails?.carId,
				auction_id: ele?.ocbDetails?.auctionId || ele?.liveAuctionDetails?.auctionId || null,
				endTime: ele?.ocbDetails?.endTime || null
			}));
			trackData.appointment_id = appointments;
			eventName = dealerId ? SegmentEvents.Viz_Dealer_car_default_list : SegmentEvents.Viz_all_cars_default_list;
		}

		const trackSearchData = {
			email_id: email,
			search_string: filters?.searchAllCarQuery || filters?.searchDealerCarQuery,
			search_results_count: carsData?.data?.length,
			dealerId: dealerId || null,
			rec_score: '',
			auction_id: cardetails?.ocbDetails?.auctionId || cardetails?.liveAuctionDetails?.auctionId || cardetails?.negoDetails?.auctionId,
			session_id: session_id,
			[cardetails?.segmentEvent ? 'appointment_id' : 'json']: trackData.appointment_id,
			source: dealerId ? EVENT_CAR_SOURCE.dealers : EVENT_CAR_SOURCE.cars,
		};


		if (filters?.searchAllCarQuery || filters?.searchDealerCarQuery) {
			trackAnalytics(cardetails?.segmentEvent || SegmentEvents.Viz_searched_car, trackSearchData);
		} else {
			trackAnalytics(cardetails?.segmentEvent || eventName, trackData);
		}
	};


	const refreshPageData = (clearData = false, isSidebarFilters = false) => {
		const basePayload = dealerId
			? { appointmentsCategory: filters?.dealerCarCategory?.id, sortBy: filters?.dealerCarSort?.id }
			: { auctionCategory: filters.ocbLive?.id, sortBy: filters.sortBy?.id };

		const searchQuery = dealerId
			? filters?.searchDealerCarQuery
			: filters?.searchAllCarQuery;

		const payload = searchQuery
			? { ...basePayload, searchQuery }
			: basePayload;

		const registrationStates = { states: sidebarFilters?.registrationStates };

		let dealerFilterPayload = null;

		if (view === ALL_CARS_TABLE_VIEW.dealersOCBView && sideBarDealerFilterPayload?.registrationStates?.states?.length === 0) {
			dealerFilterPayload = {
				...sideBarDealerFilterPayload,
				registrationStates: { states: sidebarFilters?.registrationStates }
			};
		}

		const fetchCars = dealerId
			? fetchDealerCars(dealerId, { ...payload, page: page.skip, size: page.take, paId: getPaId(userInfo) }, clearData ? { registrationStates } : sideBarDealerFilterPayload ? sideBarDealerFilterPayload : { registrationStates })
			: fetchAllCars({ ...payload, page: page.skip, size: page.take, paId: getPaId(userInfo) }, clearData ? null : sidebarFiltersPayload);

		dispatch(fetchCars);

		if (!isSidebarFilters) {
			pageRefreshState.current = true;
			isInitialPageLoad.current = false;
		}
	};


	const handlePageChange = pageNumber => {
		setPage({
			skip: pageNumber.page.skip / pageNumber.page.take,
			take: pageNumber.page.take
		});
	};

	const filterCarsByAppointmentState = (cars, selected) => {
		return cars?.filter((car) => {
			return selected === 0
				? car?.appointmentState !== APPOINTMENT_STATES.EXTENDED_AUCTION_RUNNING
				: car?.appointmentState === APPOINTMENT_STATES.EXTENDED_AUCTION_RUNNING;
		});
	};

	useEffect(() => {
		if (pageRefreshState?.current) {
			logAnalytic("");
			pageRefreshState.current = false;
		}

		if (isExclusiveFilterActive) {

			const exclusiveCarCount = carsData?.data.filter(
				(ele) => ele?.appointmentState === APPOINTMENT_STATES.EXTENDED_AUCTION_RUNNING
			).length;

			const filteredCars = filterCarsByAppointmentState(carsData?.data, exclusiveCarCount > 0 ? 1 : 0);

			setSelected(exclusiveCarCount > 0 ? 1 : 0);
			setAnyCarsData(filteredCars);
			setExclusiveCarCount(exclusiveCarCount);
		}


		if (view === ALL_CARS_TABLE_VIEW.dealersOCBView) {
			const trackData = generateTrackData(
				allCarsData, queryParameters['dealerCode'], page, "opened"
			);
			trackAnalytics(SegmentEvents.Viz_dealer_car_list, trackData);
		}

	}, [allCarsData]);



	useEffect(() => {
		dispatch(fetchSideBarFiltersData());

		return () => {
			if (view === ALL_CARS_TABLE_VIEW.dealersOCBView) {
				const trackData = generateTrackData(allCarsData, queryParameters['dealerCode'], page, "closed");
				trackAnalytics(SegmentEvents.Viz_dealer_car_list, trackData);
			}
		};
	}, []);


	useEffect(() => {
		if (!isInitialPageLoad.current) {
			refreshPageData(false);
		}
	}, [page]);

	useEffect(() => {
		if (!sidebarFilters) return;

		const query = Object.keys(queryParameters);
		updateFilterQueryParam(queryParameters, view, dispatch);
		updateHistory(filterQueryParam, dealerFilterQueryParam, view, history, location);
		setInitialFilters(query, queryParameters, sidebarFiltersPayload, view, sidebarFilters, dispatch, sideBarDealerFilterPayload);

	}, [sidebarFilters]);

	useEffect(() => {

		if (!isInitialPageLoad.current && !queryParamsKeys.some(param => Object.keys(queryParameters).includes(param))) {

			setDealerCarView(view !== ALL_CARS_TABLE_VIEW.all ? filters.dealerCarCategory.id : ALL_CARS_TABLE_VIEW.all);
			setTableColumns([...columns]);
			let prevPageNo = page?.skip;
			if (prevPageNo === INITIAL_TABLE_SETTINGS.skip) {
				if (userInfo) {
					refreshPageData(false);
				}
			} else {
				setPage({
					skip: INITIAL_TABLE_SETTINGS.skip,
					take: INITIAL_TABLE_SETTINGS.take
				});
			}
		}
	}, [filters]);


	useEffect(() => {
		setDealerCarView(view !== ALL_CARS_TABLE_VIEW.all ? filters.dealerCarCategory.id : ALL_CARS_TABLE_VIEW.all);
		setTableColumns([...columns]);

		if (userInfo && sidebarFilters && isInitialPageLoad.current) {
			let payload = dealerId
				? { appointmentsCategory: filters.dealerCarCategory?.id, sortBy: filters.dealerCarSort?.id }
				: { auctionCategory: filters.ocbLive?.id, sortBy: filters.sortBy?.id };

			const searchQuery = dealerId
				? filters?.searchDealerCarQuery
				: filters?.searchAllCarQuery;

			const completePayload = searchQuery
				? { ...payload, searchQuery }
				: payload;

			let dealerFilterPayload = null;
			const registrationStates = { states: sidebarFilters?.registrationStates };

			if (view === ALL_CARS_TABLE_VIEW.dealersOCBView && sideBarDealerFilterPayload?.registrationStates?.states?.length === 0) {
				dealerFilterPayload = {
					...sideBarDealerFilterPayload,
					registrationStates: { states: sidebarFilters?.registrationStates }
				};
			}

			const fetchCars = dealerId
				? !queryParamsKeys.some(param => Object.keys(queryParameters).includes(param)) && sideBarDealerFilterPayload === null
					? fetchDealerCars(dealerId, { ...completePayload, page: 0, size: INITIAL_TABLE_SETTINGS.take, paId: getPaId(userInfo) }, dealerFilterPayload || sideBarDealerFilterPayload || { registrationStates })
					: null
				: !queryParamsKeys.some(param => Object.keys(queryParameters).includes(param)) && sidebarFiltersPayload === null
					? fetchAllCars({ ...completePayload, page: 0, size: INITIAL_TABLE_SETTINGS.take, paId: getPaId(userInfo) }, sidebarFiltersPayload)
					: null;

			if (fetchCars) {
				dispatch(fetchCars);
			}

			isInitialPageLoad.current = false;
			pageRefreshState.current = true;
		}
	}, [userInfo, sidebarFilters]);


	useEffect(() => {
		dispatch(setDealerID(dealerId));
	}, [dealerId]);

	useEffect(() => {
		const prevPageNo = page?.skip;

		if ((sideBarDealerFilterPayload && view !== ALL_CARS_TABLE_VIEW.all) ||
			(sidebarFiltersPayload && view === ALL_CARS_TABLE_VIEW.all)) {

			if (prevPageNo === INITIAL_TABLE_SETTINGS.skip) {
				refreshPageData(false, true);
			} else {
				setPage({
					skip: INITIAL_TABLE_SETTINGS.skip,
					take: INITIAL_TABLE_SETTINGS.take
				});
			}
		}
	}, [sideBarDealerFilterPayload, sidebarFiltersPayload, filters]);


	const [selectedCars, setSelectedCars] = useState([]);
	const [resetSelection, updateResetSelection] = useState(false);

	const getDealerType = (type) => {
		if (type === CARD_TYPE.dealers) {
			return filters?.dealerCarCategory?.id;
		}
		if (filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3].id) {
			return filters.anyCar?.id;
		}
		return filters?.ocbLive?.id;

	};

	const handleSelect = (e) => {

		const filteredCars = filterCarsByAppointmentState(carsData?.data, e.selected);

		setAnyCarsData(filteredCars);
		setSelected(e.selected);
	}

	const renderTabContent = () => {
		return filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3].id && filters?.searchAllCarQuery ? anyCarsData :
			carsData?.data.map(ele => { return { ...ele, id: ele?.appointmentDetails?.appointmentId } }) || []
	}

	return (
		<>
			<div className={`page-details all-cars  ${view == ALL_CARS_TABLE_VIEW.all ? ' page-details-layout' : ''}`}>

				{view === ALL_CARS_TABLE_VIEW.all ? (
					<div className="flex-column">
						<OnGoingPais dealerId={dealerId} type={CARD_TYPE.cars} />
						<div className="title-bar">
							{!isExclusiveFilterActive ? <div className="title-text">ALL CARS</div>
								: <TabStrip className='tab-strips' selected={selected} onSelect={handleSelect}>
									<TabStripTab title="All Cars" />
									<TabStripTab title={
										<>
											<span className='tab-title'>Exclusive Cars</span>
											<span className='reassign-dealer-count'>{exclusiveCarCount}</span>
										</>
									} />
								</TabStrip>}
							<div className="view-all-pais">
								{filters?.ocbLive?.id !== ALL_CARS_OCB_LIVE_FILTER[3].id ? (
									<TablePageInfo currentPage={page.skip} total={carsData?.totalElements} take={page.take} />
								) : null}
								<AllCarsFilters
									queryParameters={queryParameters}
									clearMoreFilters={() => refreshPageData(true)}
									applied={() => { pageRefreshState.current = false; }}
								/>
							</div>
						</div>
					</div>
				) : (
					<div className="title-bar">
						<div className="recommended-cars flex">
							<div>Recommended Cars</div>
							{selectedCars?.length > 0 && (
								<div className='cars-selected-count'>({selectedCars?.length} Cars selected)</div>
							)}
						</div>
						<div className="view-all-pais">
							<TablePageInfo currentPage={page.skip} total={carsData?.totalElements} take={page.take} />
							<DealersViewFilters
								queryParameters={queryParameters}
								clearMoreFilters={() => refreshPageData(true)}
								applied={() => { pageRefreshState.current = false; }}
							/>
						</div>
					</div>

				)}

				<div className="all-cars-table multiselect-enabled-table" id="all-cars-table">
					<AppTable
						admin={true}
						noRecords={filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3].id}
						data={renderTabContent() || []}
						skip={page.skip}
						take={page.take}
						headerData={[
							...tableColumns
						]}
						pageable={filters?.ocbLive?.id !== ALL_CARS_OCB_LIVE_FILTER[3].id}
						isHoverable={true}
						totalCount={carsData?.totalElements}
						handlePageChange={handlePageChange}
						expandable={false}
						wrapperClassName={'all-cars-table-wrapper'}
						scrollTo={"all-cars-table"}
						multiSelectEnabled={(view === ALL_CARS_TABLE_VIEW.dealersOCBView || view === ALL_CARS_TABLE_VIEW.dealersAuctionView) && (
							filters?.dealerCarCategory?.id == ALL_CARS_TABLE_VIEW.dealersOCBView || filters?.dealerCarCategory?.id === ALL_CARS_TABLE_VIEW.dealersAuctionView
						) ? true : false}
						onRowSelection={(e => {
							let marked = [];
							for (const [key, value] of Object.entries(e)) {
								if (value) marked.push(key);
							}
							setSelectedCars([...marked]);
						})}
						resetSelection={resetSelection}
					/>
				</div>
			</div>
			{isLoading && dealerCarCategory == ALL_CARS_TABLE_VIEW.all && <AppLoader />}
			<ShareCarButton selectedCars={selectedCars} dealerId={dealerId ? [Number(dealerId)] : []} auctionType={filters.dealerCarCategory.id} refresh={() => {
				updateResetSelection(!resetSelection);
				refreshPageData();
				setSelectedCars([]);
			}} />

			{isModal && <CarDetails type={getCurrentType(view, isPaiCar)}
				dealerType={getDealerType(view === ALL_CARS_TABLE_VIEW.all ? CARD_TYPE.cars : CARD_TYPE.dealers)}
				refresh={() => {
					refreshPageData();
				}}
			/>}
		</>
	);
};
export default AllCars;
